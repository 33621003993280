import React, { useEffect, useState } from "react";
import Home_header from "./Home_header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";

const Secuirty = () => {
  const navigate = useNavigate();
  const navigateprofile = () => {
    navigate("/userprofile")
  }
  const navigatetfa = () => {
    navigate("/authentication")
  }

  const navigatepasswordpage = () => {
    navigate("/change_password")
  }
  useEffect(() => {
    findProfile();
  }, [0]);

  const [siteLoader, setSiteLoader] = useState(false);
  const [userDetails, setUserDetails] = useState("")
  const [userNumber, setuserNumber] = useState("")
  const findProfile = async () => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      setSiteLoader(false);
      setUserDetails(resp.data)
      const maskedNumber = "****" + resp.data.mobileNumber.slice(-5);
      setuserNumber(maskedNumber)
    } catch (error) { }
  };
  return (

    <main className="secuirty_main_page">
      <div>
        <Home_header />
      </div>
      {siteLoader == true ?
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        : <>
          <section className="bull_secuirty_section">
            <div className="container">
              <div className="secuirty_title kyc_title">
                <h2>Security Settings</h2>
                {/* <p>
              Status: <span>verified</span>
            </p> */}
              </div>
              {/* <div className="kyc_border"></div> */}
              <div className="row d-flex justify-content-center">
                <div className="col-lg-9">
                  <div className="secuirty_box">
                    <h2>Dual-Factor Security Check</h2>
                    <p>
                      Secure your account by activating both Email and Mobile
                      Authentication. With these safeguards in place, you'll gain
                      <br />
                      the ability to track every transaction in real time, ensuring
                      enhanced protection for all your activities.
                    </p>

                    <div className="email_content">
                      <div className="email_img">
                        <img src={require("../images/dashboard/email_icon.png")} />
                        <div className="email_content_text">
                          <h3>Email</h3>
                          <p>
                            To safeguard your transactions and
                            <br /> account, use your email.
                          </p>
                        </div>
                      </div>
                      <div className="enabled_text d-flex">
                        {!userDetails.email ? (
                          <>
                            <svg
                              class="ICInfo-filled_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              color="#DC3545"
                              fill="currentColor"
                            >
                              <path d="M512 42.656c259.2 0 469.344 210.112 469.344 469.344S771.2 981.344 512 981.344 42.656 771.2 42.656 512 252.768 42.656 512 42.656zm0 640a53.344 53.344 0 100 106.656 53.344 53.344 0 000-106.656zm0-469.312c-23.552 0-42.656 19.104-42.656 42.656v341.344a42.656 42.656 0 0085.312 0V256c0-23.552-19.104-42.656-42.656-42.656z"></path>
                            </svg>
                            <p className="text-danger">Disable</p>
                          </>
                        ) : (
                          <>
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"

                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span>Enable</span>
                          </>
                        )}

                      </div>
                      {/* <div className="enabled_text d-flex">
                    <svg
                      class="ICSuccess-filled_svg__icon"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                    >
                      <path d="M180.128 180.128C265.024 95.232 382.4 42.656 512 42.656s246.976 52.576 331.872 137.472C928.768 265.024 981.344 382.4 981.344 512s-52.576 246.976-137.472 331.872C758.976 928.768 641.6 981.344 512 981.344s-246.976-52.576-331.872-137.472C95.232 758.976 42.656 641.6 42.656 512s52.576-246.976 137.472-331.872zm575.36 234.048a42.656 42.656 0 10-60.352-60.352L469.312 579.648l-97.824-97.824a42.656 42.656 0 10-60.352 60.352l128 128a42.656 42.656 0 0060.352 0l256-256z"></path>
                    </svg>
                    {!userDetails.email ? <p className="text-danger">Disabled</p> : "Enabled"}
                  </div> */}
                      <a href="/userprofile">
                        <div className="enabel_view_btn">
                          <button className="w20">View</button>
                        </div>
                      </a>
                    </div>
                    {/* <div className="kyc_border"></div> */}
                    {/* <div className="email_content">
                      <div className="email_img">
                        <img src={require("../images/dashboard/phone.png")} />
                        <div className="email_content_text">
                          <h3>Phone</h3>
                          <p>
                            To safeguard your transactions and
                            <br /> account, use your phone number.
                          </p>
                        </div>
                      </div>

                    
                      <div className="enabled_text d-flex">

                        {!userDetails.mobileNumber ? (
                          <>
                            <svg
                              class="ICInfo-filled_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              color="#DC3545"
                              fill="currentColor"
                            >
                              <path d="M512 42.656c259.2 0 469.344 210.112 469.344 469.344S771.2 981.344 512 981.344 42.656 771.2 42.656 512 252.768 42.656 512 42.656zm0 640a53.344 53.344 0 100 106.656 53.344 53.344 0 000-106.656zm0-469.312c-23.552 0-42.656 19.104-42.656 42.656v341.344a42.656 42.656 0 0085.312 0V256c0-23.552-19.104-42.656-42.656-42.656z"></path>
                            </svg>
                            <p className="text-danger">Not upload</p>
                          </>
                        ) : (
                          <>
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"

                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span> {userNumber}</span>
                          </>
                        )}
                      </div>
                      <a href="/userprofile">
                        <div className="enabel_view_btn">
                          <button>Change</button>
                        </div>
                      </a>
                    </div> */}
                    {/* <div className="kyc_border"></div> */}
                    <div className="email_content">
                      <div className="email_img">
                        <img src={require("../images/dashboard/phone.png")} />
                        <div className="email_content_text" onClick={navigatetfa}>
                          <h3>2FA</h3>
                          <p>
                            To safeguard your transactions and
                            <br /> account, use your phone number.
                          </p>
                        </div>
                      </div>

                      <div className="enabled_text d-flex">
                        {userDetails.tfastatus === 0 ? (
                          <>
                            <svg
                              class="ICInfo-filled_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              color="#DC3545"
                              fill="currentColor"
                            >
                              <path d="M512 42.656c259.2 0 469.344 210.112 469.344 469.344S771.2 981.344 512 981.344 42.656 771.2 42.656 512 252.768 42.656 512 42.656zm0 640a53.344 53.344 0 100 106.656 53.344 53.344 0 000-106.656zm0-469.312c-23.552 0-42.656 19.104-42.656 42.656v341.344a42.656 42.656 0 0085.312 0V256c0-23.552-19.104-42.656-42.656-42.656z"></path>
                            </svg>
                            <p className="text-danger">Disable</p>
                          </>
                        ) : (
                          <>
                            <svg
                              class="ICSecuritySetting_svg__icon"
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"

                              fill="currentColor"
                            >
                              <path d="M755.488 414.176a42.656 42.656 0 10-60.352-60.352L469.312 579.648 350.144 460.48a42.656 42.656 0 10-60.352 60.352l149.344 149.344a42.656 42.656 0 0060.352 0l256-256z"></path>
                              <path d="M571.968 58.336a213.216 213.216 0 00-119.584 0L208.192 129.6A170.688 170.688 0 0085.344 293.44v133.856A604.192 604.192 0 00441.6 978.272a172.001 172.001 0 00140.928 0 604.16 604.16 0 00356.16-550.88V293.408A170.624 170.624 0 00815.872 129.6L572 58.368zm-95.68 81.92a127.84 127.84 0 0171.744 0l243.872 71.232a85.344 85.344 0 0161.408 81.92v133.984a518.784 518.784 0 01-305.824 473.056 86.496 86.496 0 01-70.944 0 518.88 518.88 0 01-305.92-473.152V293.44c0-37.92 25.024-71.296 61.44-81.92l244.192-71.264z"></path>
                            </svg>
                            <span>Enable</span>
                          </>
                        )}

                      </div>

                      <a href="/authentication">
                        <div className="enabel_view_btn">
                          <button>Change</button>
                        </div>
                      </a>
                    </div>
                    {/* <div className="kyc_border"></div> */}
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="secuirty_box">
                    <h2>Advanced Settings</h2>

                    <div className="email_content">
                      <div className="email_img">
                        <img src={require("../images/dashboard/login_ico.png")} />
                        <div className="email_content_text">
                          <h3>Login Password</h3>
                          <p>For account login.</p>
                        </div>
                      </div>
                      <a href="change_password">
                        <div className="enabel_view_btn">
                          <button> Change</button>
                        </div>
                      </a>
                    </div>
                    {/* <div className="kyc_border"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }
    </main>
  );
};

export default Secuirty;
