import React, { useEffect } from "react";
import Header from "./Home_header";
import { Button } from "@mui/material";
import Select from "react-dropdown-select";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { setAuthorization } from "../core/service/axios";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
function VerifyTfa() {
  const initialFormValue = {
    tfa: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const navigate = useNavigate();

  const { tfa } = formValue;
  const { state } = useLocation();
  const [siteLoader, setSiteLoader] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };
  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.tfaVerify,
        payload: {
          userToken: tfa,
          socketToken: state?.socketToken,
        },
      };
      var resp = await postMethod(data);
      console.log("tfa login===", resp);
      if (resp.status) {
        toast.success(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", resp.tfa);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    }
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };


  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form smart_bull_login minheight100">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center ">
                  <div className="col-lg-5 smart_login_row">
                    <div className="card_login bull_login_card">
                      <h3 className="mb-3">Enter 2FA code to login</h3>

                      <div>
                        <label className="lable_text bull_email-text">
                          Your 2FA
                        </label>
                        <div class="form_login_input bull_form_login_input">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Your 2FA"
                            autocomplete="off"
                            name="tfa"
                            value={tfa}
                            onChange={handleChange}
                          />
                        </div>
                        {tfaValidate == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.tfa}{" "}
                          </p>
                        ) : (
                          ""
                        )}

                        <div class="form_login_input smart_bull_form_login_input_buttons">
                          {buttonLoader == false ? (
                            <Button onClick={formSubmit} className="mt-3">
                              Login
                            </Button>
                          ) : (
                            <Button className="mt-3">Loading...</Button>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default VerifyTfa;
