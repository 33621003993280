import Reac from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Header from "./Home_header";
import { Bars } from "react-loader-spinner";
import { Button } from "@mui/material";

import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import OTPInput, { ResendOTP } from "otp-input-react";

function VerifyEmail() {
  const [OTP, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [buttonLoader, setbuttonLoader] = useState(false);

  const navigate = useNavigate();

  const submit = async () => {
    try {
      if (OTP !== "") {
        console.log(OTP, "otp-=-=-");
        var obj = {
          emailOtp: OTP,
          email: localStorage.getItem("useremail"),
        };

        var data = {
          apiUrl: apiService.emailotpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/login");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Enter OTP");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const resendOTP = async (payload) => {
    try {
      var obj = {
        email: localStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();

console.log(e,)
   
  };


  const [siteLoader, setSiteLoader] = useState(false);

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form minheight100">
          <div className="container">
            {siteLoader == true ? (
              <div className="dashboard_content_section loadercss">
                <Bars
                  height="40"
                  width="40"
                  color="#1062fe"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col-lg-5 smart_login_row">
                    <div className="card_login mt25">
                      <h3>Email Verification</h3>
                      <div>
                        <div class="form_login_input">
                          <label className="mb-3">
                            Enter the verification code received to your email
                          </label>
                          <input
                           autocomplete="off"
                            type="number"
                            class="form-control"
                            placeholder="Enter OTP"
                            name="email"
                            min={1000}
                            value={OTP}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(
                                evt.key
                              ) && evt.preventDefault()
                            }
                            onChange={(e) => setOTP(e.target.value)}
                          />
                        </div>


                        <div class="form_login_input mt-3">
                          {buttonLoader == false ? (
                            <Button onClick={submit}>Submit</Button>
                          ) : (
                            <Button>Loading...</Button>
                          )}
                        </div>
                        <p className="resend_text ptag">Didn't receive code?<span onClick={resendOTP} style={{ color: "blue", cursor: "pointer" }}>Resend </span ></p>

                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default VerifyEmail;
