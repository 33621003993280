import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {

    return (
        <main className="dashboard_page">
            <section>
                <Home_header />
            </section>

            <section className="dashboard_content_section">
                <div className="container">
                    <div className="smart_dashboard_content">
                        <h2>Welcome to Smart Bull</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="dash_board_profile_content">
                                    <div className="contentpage">
                                        <h3>Your Premier Crypto Trading Platform!</h3>

                                        <p>At Smart Bull, we hold a firm belief in the transformative power of cryptocurrencies within the financial landscape. Our mission is to provide an inclusive and accessible platform, facilitating seamless entry into the dynamic world of digital assets for both seasoned traders and beginners alike.
                                        </p>

                                        <h5>Our Background</h5>
                                        <p>Smart Bull is an innovative crypto trading platform founded by a dedicated team of cryptocurrency enthusiasts, industry experts, blockchain developers, and financial professionals. Our collective vision is to establish a secure, transparent, and user-friendly environment, empowering crypto enthusiasts from all walks of life to explore and capitalize on the vast opportunities offered by the crypto markets.</p>

                                        <h5>Our Offerings</h5>
                                        <p>At Smart Bull, we offer an intuitive and feature-rich trading platform where users can effortlessly buy, sell, and trade a comprehensive range of cryptocurrencies. From established cryptocurrencies like Bitcoin, Ethereum, and Ripple to emerging altcoins, our platform supports a diverse array of digital assets. Furthermore, we equip users with real-time market data, sophisticated charting tools, and comprehensive analytics, enabling informed decision-making amidst the fast-paced crypto landscape.</p>

                                        <h5>User-Centric Interface</h5>
                                        <p>Our platform is thoughtfully designed with a focus on simplicity and efficiency. Users, regardless of their trading expertise, will find our interface easy to navigate, ensuring a seamless trading experience.</p>

                                        <h5>Robust Security Measures</h5>
                                        <p>The security of our users' funds and personal information is of paramount importance. Smart Bull employs cutting-edge security protocols, including multi-factor authentication, encryption mechanisms, and cold storage solutions, to safeguard assets from potential threats.</p>
                                        <h5>Extensive Asset Portfolio</h5>
                                        <p>With an extensive selection of cryptocurrencies available for trading, users can explore new investment opportunities and diversify their portfolios seamlessly.</p>
                                        <h5>Exceptional Customer Support</h5>
                                        <p>We place a premium on delivering stellar customer support. Our dedicated team is readily available to address any queries or concerns, guaranteeing a superior user experience on our platform.</p>
                                        <h5>Educational Resources</h5>
                                        <p>Recognizing the complexities of the crypto industry, especially for newcomers, we provide a wealth of educational resources such as tutorials, guides, and market insights to enhance users' trading knowledge and skills.</p>
                                        <h5>Adherence To Regulations</h5>
                                        <p>Smart Bull complies with all necessary regulations and industry standards, ensuring a safe and legal trading environment for our users.</p>

                                        <h4>Join Our Community</h4>
                                        <p>Whether you are a seasoned trader or a novice venturing into the world of cryptocurrencies, Smart Bull extends a warm invitation to you. Our platform is meticulously designed to cater to your unique requirements and empower you to harness the full potential of crypto trading. Come, be a part of our vibrant trading community, and together, let us embark on a journey of financial empowerment through cryptocurrencies.</p>

                                        <h5>Disclaimer</h5>
                                        <p>Trading cryptocurrencies involves inherent risks, and past performance should not be considered indicative of future results. We strongly advise conducting thorough research and, if necessary, seeking professional advice before engaging in any trading activities.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    );
}

export default Home;
