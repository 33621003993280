import React, { useEffect } from "react";
import Home_header from "./Home_header";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import { Bars } from "react-loader-spinner";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

const User_profile = () => {

  useEffect(() => {
    findProfile();
  }, [0]);

  const handleChange = async (e) => {
    e.preventDefault();
    console.log()

    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
    // if(e.target.name=="mobileNumber"){
    //   validate(formData);
    // }
  };
  const initialFormValue = {
    userName: "",
    email: "",
    mobileNumber: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [siteLoader, setSiteLoader] = useState(false);

  const {
    userName,
    email,
    mobileNumber
  } = formValue;


  const navigate = useNavigate()
  const back = () => {
    navigate("/security")
  }

  const findProfile = async () => {
    try {
      setSiteLoader(true);
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        formValue.userName = resp.data.username;
        formValue.email = resp.data.email;
        formValue.mobileNumber = resp.data.mobileNumber;
      }
    } catch (error) { }
  };

  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [mobileNumberValidate, setmobileNumberValidate, mobileNumberValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");

  const validate = (values) => {
    let errors = {};

    if (!formValue.userName) {
      errors.name = "Name is a required field !";
      setnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(formValue.userName)) {
      errors.name = "Username must start with a letter !";
      setnameValidate(true);
    } else if (formValue.userName.length < 3 || formValue.userName.length > 25) {
      errors.name = "Name must have an  3 to 25 characters !";
      setnameValidate(true);
    }
    else if (!formValue.mobileNumber) {
      errors.mobileNumber = "Mobile Number is required!";
      setmobileNumberValidate(true);
    } else if (formValue.mobileNumber.length > 15) {
      errors.mobileNumber = "Mobile Number is too long!";
      setmobileNumberValidate(true);
    } else {
      setmobileNumberValidate(false);
      setnameValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const submit_profile = async () => {
    validate(formValue);
    if (
      // mobileNumberValidateref.current == false && 
      nameValidateref.current == false) {
      var obj = {
        Mobile: formValue.mobileNumber,
        Email: formValue.email,
        username: formValue.userName
      };

      var data = {
        apiUrl: apiService.update_profile,
        payload: obj,
      };
      var resp = await postMethod(data);
      toast(resp.Message);
      navigate("/security")

    } else {
      validate()
    }
  }


  return (
    <main className="user_profile_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? 
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>:  <>
      <section className="user_profile_section">
        <div className="container">
          <div className="profile_title" onClick={back}>
            <i class="fa-solid fa-angle-left" ></i>Profile
          </div>
          {/* <div className="kyc_border"></div> */}
          <div className="row justify-content-center">
            <div className="col-lg-5 smart_login_row ">
              <div className="card_login register_page_login_card user-profile p-4">
                <div>
                  <label className="lable_text">User Name</label>
                  <div class="form_login_input">
                    <input
                      type="text"
                      name="userName"
                      autocomplete="off"
                      minLength={3}
                      maxLength={25}
                      value={userName}
                      onChange={handleChange}
                      class="form-control"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  {
                    validationnErr && validationnErr.name && <p className="errorcss">{validationnErr.name}</p>
                  }

                </div>
                <div>
                  <label className="lable_text">Email</label>
                  <div class="form_login_input">
                    <input
                      type="text"
                      autocomplete="off"
                      name="email"
                      value={email}
                      disable
                      // onChange={handleChange} 
                      class="form-control"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  {/* {
                    validationnErr && validationnErr.email && <p className="errorcss">{validationnErr.email}</p>
                  } */}
                </div>
                {/* <div>
                  <label className="lable_text">Phone Number</label>
                  <div class="form_login_input">
                    <input
                      type="number"
                      name="mobileNumber"
                      minLength={5}
                      maxLength={15}
                      class="form-control"
                      placeholder="Enter Your Phone Number"
                      onChange={handleChange}
                      value={mobileNumber}
                      onKeyDown={(e) => ["ArrowUp", "ArrowDown", "e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    />
                  </div>
                  {
                    validationnErr && validationnErr.mobileNumber && <p className="errorcss">{validationnErr.mobileNumber}</p>
                  }
                </div> */}
                <div className="profile_submit_btn">
                  <button onClick={submit_profile}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </>}
    </main>
  );
};

export default User_profile;
