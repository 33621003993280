import React, { useEffect } from "react";
import Home_header from "./Home_header";
import { Button } from "@mui/material";
import Footer from "./Footer";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useState from "react-usestateref";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Moment from "moment";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";

function Home() {

    return (
        <main className="dashboard_page">
            <section>
                <Home_header />
            </section>

            <section className="dashboard_content_section">
                <div className="container">
                    <div className="smart_dashboard_content">
                        <h2>Smart Bull – Terms And Condition</h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="dash_board_profile_content">
                                    <div className="contentpage">
                                        <p>The provided text appears to be a portion of the "Terms of Use" agreement for a service called "Smart Bull." It outlines the conditions and requirements for using the Smart Bull Services, which seem to be related to digital currencies and their derivatives. Here's a summary of the key points:</p>

                                        <h5>1. PARTIES:</h5>
                                        <p>The agreement is between the user ("you" or "your") and the operator of Smart Bull.</p>

                                        <h5>2. ACCEPTANCE:</h5>
                                        <p>By accessing, downloading, using, or clicking "I agree," you are agreeing to be bound by these Terms of Use and the Privacy Policy provided at support@smartbull.io.</p>

                                        <h5>3. ADDITIONAL TERMS:
                                        </h5>
                                        <p>Some features of the Services may have specific additional terms and conditions applicable to them.</p>

                                        <h5>4. ARBITRATION PROVISION:</h5>
                                        <p>The agreement contains an arbitration provision that mandates all claims to be resolved through legally binding arbitration. Details of the arbitration provision can be found in Article 10, titled "Resolving Disputes: Forum, Arbitration, Class Action Waiver."</p>

                                        <h5>5. RISKS OF DIGITAL CURRENCIES:</h5>
                                        <p>Users are informed that digital currencies and their derivatives can fluctuate significantly in value, and there is a risk of economic losses when buying, selling, holding, or investing in them. Users acknowledge and agree to assume all risks related to using Smart Bull Services for transactions involving digital currencies and their derivatives. Smart Bull will not be liable for any such risks or adverse outcomes.</p>

                                        <h5>6. ACKNOWLEDGMENT OF ACCEPTANCE:</h5>
                                        <p>By accessing, using, or attempting to use Smart Bull Services, you acknowledge that you have read, understood, and accepted these Terms of Use. If you disagree, you should not use Smart Bull or its services.
                                        </p>

                                        <p className="mt-5">By accessing, using or attempting to use Smart Bull Services in any capacity, you acknowledge that you accept and agree to be bound by these Terms. If you do not agree, do not access Smart Bull or utilize Smart Bull services.</p>


                                        <h4>I. DEFINITIONS</h4>

                                        <p>The provided section contains definitions of various terms used in the "Terms of Use" agreement for Smart Bull Exchange. These definitions are crucial for understanding the context and scope of the agreement. Here are the defined terms:</p>


                                        <h5>1. Smart Bull EXCHANGE -
                                        </h5>
                                        <p>It refers to the entire ecosystem comprising Smart Bull websites, mobile applications, clients, applets, and other applications developed to offer Smart Bull Services. This may also include independently operated platforms within the ecosystem
                                        </p>

                                        <h5>2. Smart Bull Accounts -
                                        </h5>
                                        <p>These are virtual accounts opened by Smart Bull for users to record their usage of Smart Bull Services, transactions, asset changes, and basic information. These accounts serve as the foundation for users to enjoy and exercise their rights on Smart Bull
                                        </p>

                                        <h5>3. Smart Bull Fiat Account -
                                        </h5>
                                        <p>It refers to the record of fiat currencies' balance (if any) enabled through an electronic money wallet or a similar account service provided by a Fiat Partner.
                                        </p>

                                        <h5>4. Smart Bull Services -
                                        </h5>
                                        <p>These are various services provided to users by Smart Bull based on Internet and/or blockchain technologies. The services are offered through Smart Bull websites, mobile applications, clients, and other forms. Examples of Smart Bull Services include digital assets trading, Smart Bull academy, Smart Bull launchpad, and Smart Bull blockchain.</p>

                                        <h5>5. Smart Bull Operators -
                                        </h5>
                                        <p>These refer to all parties responsible for running Smart Bull and providing Smart Bull Services. This includes legal persons, unincorporated organizations, and teams associated with Smart Bull.
                                        </p>

                                        <h5>6. Smart Bull Platform Rules -</h5>
                                        <p>It includes all rules, interpretations, announcements, statements, letters of consent, and other contents released by Smart Bull. It also encompasses regulations, implementation rules, product process descriptions, and announcements published in the Help Center or within products or service processes.
                                        </p>

                                        <h5>7. Digital Currency -
                                        </h5>
                                        <p>It refers to a form of currency that exists entirely in digital or virtual form, not backed by a government or physical asset. Digital currencies are based on cryptography and blockchain technology.
                                        </p>

                                        <h5>8. Digital Assets -
                                        </h5>
                                        <p>These are digital or virtual assets that are stored and exchanged electronically. They can include digital currencies or digital representations of real-world assets, such as property titles, music, or artwork.
                                        </p>

                                        <h5>9 Fiat Partner -
                                        </h5>
                                        <p>A fiat partner is a company or financial institution that provides a bridge between traditional fiat currencies (e.g., USD or EUR) and cryptocurrencies, enabling users to buy and sell digital currencies using fiat money
                                        </p>

                                        <h5>10. Fiat Services means -
                                        </h5>
                                        <p>These are financial services that involve traditional government-issued currencies (fiat currencies) such as USD, EUR, etc., as opposed to cryptocurrencies. Examples include banking, credit cards, loans, and money transfers.</p>

                                        <h5>11. KYC -
                                        </h5>
                                        <p>KYC stands for "Know Your Customer," a regulatory requirement that financial institutions and businesses must follow to verify the identity of their customers. It helps prevent fraud, money laundering, and terrorist financing
                                        </p>

                                        <h5>12. Spot Trading -
                                        </h5>
                                        <p>Spot trading refers to the immediate buying and selling of financial assets for delivery or settlement within a short period, usually a few days.
                                        </p>

                                        <h5>13. Users</h5>
                                        <p>This term refers to all individuals, institutions, or organizations that access, download, or use Smart Bull or its services and meet the criteria and conditions set by Smart Bull Exchange
                                        </p>

                                        <h4>II. GENERAL PROVISIONS</h4>

                                        <h5>1. About these terms</h5>
                                        <p>
                                            <b>a. CONTRACTUAL RELATIONSHIP:</b> The terms and conditions presented here establish a legally binding agreement between you (referred to as "you" or "your") and Smart Bull operators. This agreement defines the mutual obligations and responsibilities of both parties.
                                        </p>
                                        <p>
                                            <b>b. SUPPLEMENTARY TERMS:</b> Due to the rapid development of Digital Currencies and Smart Bull these Terms between you and Smart Bull Operators do not enumerate or cover all rights and obligations of each party, and do not guarantee full alignment with needs arising from future development. Therefore, the Privacy Policy OF Smart Bull PLATFORM RULES, and all other agreements entered into separately between you and Smart Bull are deemed supplementary terms that are an integral part of these Terms and shall have the same legal effect. Your use of Smart Bull Services is deemed your acceptance of the above supplementary terms.
                                        </p>
                                        <p>
                                            <b>c. CHANGES TO THESE TERMS:</b> Smart Bull reserves the right to change or modify these Terms in its discretion at any time. We will notify such changes by updating the terms on its website and will modify the [Last revised] date displayed on this page. Any and all modifications or changes to these terms Will become effective upon publication on the website Or release to users. Therefore, your continued use of Smart Bull services is deemed your acceptance of the Modified agreement and rules. If you do not agree to Any changes to these terms, you must stop using Smart Bull services immediately. You are recommended to Frequently review these terms to ensure your Understanding of the terms and conditions that apply To your access to and use of Smart Bull services.
                                        </p>
                                        <p>
                                            <b>d. Prohibition of Use:</b> By accessing And using Smart Bull services, you represent and warrant That you have not been included in any trade Interdiction or economic sanctions list (such as the United nations security council sanctions list), the List of specially designated nationals maintained by Ofac (the office of foreign assets control of the u.S. Department of the treasury), or the denied persons or Entity list of the u.S. Department of commerce. Smart Bull reserves the right to choose markets and Jurisdictions to conduct business, and may restrict or Refuse, in its discretion, the provision of Smart Bull Services in certain countries or regions.
                                        </p>

                                        <h5>2. About Smart Bull</h5>
                                        <p>
                                            Smart Bull is a leading digital asset exchange offering secure and seamless access to a diverse selection of cryptocurrencies. Founded by a team of seasoned professionals who deeply believe in the transformative potential of blockchain technology, our exchange is dedicated to providing users with a smooth and secure trading experience.
                                        </p>
                                        <p>
                                            We are committed to creating a user-friendly platform that is accessible to everyone, regardless of their level of experience with cryptocurrencies. Our goal is to empower users with the latest market data, real-time pricing, and advanced trading tools. Ensuring the safety of our users' funds is of paramount importance to us, and we have implemented robust security measures and best practices to safeguard their assets.
                                        </p>
                                        <p>
                                            Compliance with relevant regulations and laws is a top priority for us, and we maintain the highest standards of transparency and integrity in all our operations. Our mission is to enable our users to take control of their financial futures by offering a secure, reliable, and user-friendly platform to buy, sell, and trade cryptocurrencies.
                                        </p>
                                        <p>
                                            While we strive to maintain the accuracy of the information provided through our services, we cannot guarantee its absolute accuracy, applicability, reliability, integrity, or performance. Smart Bull shall not be held liable for any direct or indirect loss or damage caused by the use of this content. It is essential for users to understand the risks involved in digital assets trading and to trade responsibly based on their capabilities.
                                        </p>
                                        <p>
                                            Please note that Smart Bull does not provide investment or consulting advice of any kind. The information we provide is intended to assist users in making independent decisions. Users are encouraged to stay informed about the risks associated with digital assets trading and act responsibly in their trading activities.
                                        </p>

                                        <h5>3. Smart Bull Account Registration and Requirements</h5>

                                        <h5>A. REGISTRATION</h5>
                                        <p>
                                            To use Smart Bull Services, all users must apply for a Smart Bull Account at <a href="https://www.smartbull.io/signup">https://www.smartbull.io/signup</a>. During registration, you must provide the requested information and agree to these Terms, the Privacy Policy, and other Smart Bull Platform Rules. Smart Bull reserves the right to refuse to open an account for any user. You are responsible for providing accurate and complete information during registration and updating it promptly to maintain its integrity and accuracy. Each user (individual, business, or legal entity) is allowed to have only one main account at a time. However, users may open one or more subaccounts under the main account with Smart Bull' consent, subject to certain conditions.
                                        </p>

                                        <h5>B. ELIGIBILITY</h5>
                                        <p>
                                            By registering for a Smart Bull Account, you confirm and verify the following:
                                            <ul>
                                                <li>You are at least 18 years old or have the legal age to form a binding contract according to applicable laws</li>
                                                <li>As an individual, legal person, or organization, you have the full legal capacity and necessary authorizations to enter into these Terms.</li>
                                                <li>You have not been suspended or removed from using the Smart Bull Platform or Smart Bull Services previously.</li>
                                                <li>You do not already have an existing Smart Bull Account.</li>
                                                <li>You are not accessing the Smart Bull Platform or Services from a Restricted Location, nor acting on behalf of someone or a legal entity located in a Restricted Location.</li>
                                                <li>If you represent a legal entity, you have the necessary rights and authorizations to bind that entity and access and use the Smart Bull Platform and Services on its behalf.</li>
                                                <li>Your use of the Smart Bull Platform and Services complies with all applicable laws and regulations, including anti-money laundering, anti-corruption, and counter-terrorist financing regulations.</li>
                                            </ul>
                                            Please note that certain products and services within the Smart Bull Platform may not be available or may be restricted in certain jurisdictions or regions due to legal requirements. Users are responsible for being informed about and complying with any restrictions and/or requirements regarding their access to and use of the Smart Bull Platform.
                                        </p>

                                        <h5>C. USER IDENTITY VERIFICATION</h5>
                                        <p>
                                            Registering a Smart Bull Account implies your agreement to provide the necessary personal information for identity verification. This information will be used to verify your identity, detect money laundering, terrorist financing, fraud, and other financial crimes, or for other lawful purposes stated by Smart Bull. We will collect, use, and share this information in accordance with our Privacy Policy. You authorize us to retain this information for the duration of your active account and up to five (5) years after your account is closed, in line with global industry data storage standards. Additionally, you permit us to conduct necessary investigations, directly or through a third party, to verify your identity or protect you and/or us from financial crimes, such as fraud. The required information may include your name, email address, contact information, phone number, username, government-issued ID, date of birth, and other relevant details provided during account registration.
                                        </p>

                                        <h5>D. ACCOUNT USAGE REQUIREMENTS</h5>
                                        <p>
                                            A Smart Bull Account can only be used by the account registrant. Smart Bull reserves the right to suspend, freeze, or cancel the use of Smart Bull Accounts by anyone other than the account registrant. If you suspect or become aware of any unauthorized use of your account credentials (username and password), you must notify Smart Bull immediately. Smart Bull is not liable for any loss or damage arising from the use of your Smart Bull Account by you or any third party, whether authorized or unauthorized.
                                        </p>

                                        <h5>E. ACCOUNT SECURITY</h5>
                                        <p>
                                            Smart Bull prioritizes the security of user funds and has implemented industry-standard protection for Smart Bull Services. However, individual user actions can pose risks. You agree to treat your access credentials (e.g., username and password) as confidential information and refrain from disclosing them to any third party. You are solely responsible for ensuring the security of your Smart Bull Account and personal information.
                                        </p>
                                        <p>
                                            You are fully responsible for keeping your Smart Bull Account and password safe, and all transactions conducted under your Smart Bull Account. Smart Bull bears no liability for any loss or consequences resulting from authorized or unauthorized use of your account credentials, including but not limited to information disclosure, agreement consent, rule acceptance, or online agreement renewal.
                                        </p>
                                        <p>
                                            By creating a Smart Bull Account, you hereby agree that:
                                            <ul>
                                                <li>You will notify Smart Bull immediately if you are aware of any unauthorized use of your Account and password or any other violation of security rules;</li>
                                                <li>You will strictly abide by all mechanisms or procedures of Smart Bull regarding security, authentication, trading, charging, and withdrawal; and</li>
                                                <li>You will take appropriate steps to logout from Smart Bull at the end of each visit.</li>
                                            </ul>
                                        </p>

                                        <h4>III. Smart Bull SERVICES</h4>
                                        <p>
                                            After completing the registration process of Smart Bull Account and verification of personal information, you may use Smart Bull Services which includes but not limited to Spot trading, Fiat services, Margin trading, Saving, Staking, etc. in accordance with the provisions of these Terms (including Smart Bull Platform Rules and other individual agreements). Smart Bull has the right to:
                                            <ul>
                                                <li>Provide, modify or terminate, in its discretion, any Smart Bull Services; and</li>
                                                <li>Allow or prohibit some Users’ use of any Smart Bull Services in accordance with relevant Smart Bull Platform Rules.</li>
                                            </ul>
                                            Smart Bull has exclusive authority to:
                                            <ul>
                                                <li>determine which digital asset or fiat currencies are listed on the platform and can be traded. Smart Bull may add, remove, or suspend the trading of digital assets at its sole discretion;</li>
                                                <li>set the minimum and maximum limits for each transaction, as well as the fees and charges applicable to the transactions;</li>
                                                <li>implement measures to ensure compliance with relevant laws and regulations, such as anti-money laundering (AML) and counter-terrorism financing (CTF) requirements;</li>
                                                <li>take necessary actions to maintain the security, integrity, and stability of the platform, including temporarily suspending or restricting access to the platform during maintenance or in case of a security breach or other emergency;</li>
                                                <li>amend, modify, or update the terms of service, privacy policy, platform rules, or any other agreements or policies related to the use of Smart Bull services.</li>
                                            </ul>
                                        </p>
                                        <h4>IV. LIABILITIES</h4>

                                        <h5>1. Disclaimer of Damages and Limitation of Liabilities</h5>
                                        <p>To the maximum extent permitted by applicable law, in no Event will Smart Bull, its affiliates and their respective Shareholders, members, directors, officers, employees, Attorneys, agents, representatives, suppliers or Contractors be liable for any incidental, indirect, Special, punitive, consequential or similar damages or Liabilities whatsoever (including, without limitation, Damages for loss of data, information, revenue, profits Or other businesses or financial benefits) arising out Of Smart Bull services, any performance or non-performance Of Smart Bull services, or any other product, service or Other item provided by or on behalf of Smart Bull and its Affiliates, whether under contract, statute, strict Liability or other theory even if Smart Bull has been Advised of the possibility of such damages except to the Extent of a final judicial determination that such Damages were a result of Smart Bull’s gross negligence, Fraud, willful misconduct or intentional violation of Law. Some jurisdictions do not allow the exclusion or Limitation of incidental or consequential damages, so The above limitation may not apply to you.</p>
                                        <p>Notwithstanding the foregoing, in no event will the Liability of Smart Bull, its affiliates and their Respective shareholders, members, directors, officers, Employees, attorneys, agents, representatives, suppliers Or contractors arising out of services offered by or on Behalf of Smart Bull and its affiliates, any performance or Non-performance of Smart Bull services, or any other Product, service or other item, whether under contract, Statute, strict liability or other theory, exceed the Amount of the fees paid by you to Smart Bull under these Terms in the twelve-month period immediately preceding the event Giving rise to the claim for liability.</p>

                                        <h5>2. Disclaimer of Warranties</h5>
                                        <p>To the maximum extent permitted under applicable law, Smart Bull services, Smart Bull materials and any product, Service or other item provided by or on behalf of Smart Bull are offered on an “as is” and “as available” Basis, and Smart Bull expressly disclaims, and you waive, Any and all other warranties of any kind, whether Express or implied, including, without limitation, Warranties of merchantability, fitness for a particular Purpose, title or non-infringement or warranties arising From course of performance, course of dealing or usage In trade. Without limiting the foregoing, Smart Bull does Not represent or warrant that the site, Smart Bull services Or Smart Bull materials are accurate, complete, reliable, Current, error-free, or free of viruses or other harmful Components. Smart Bull does not guarantee that any order Will be executed, accepted, recorded or remain open. Except for the express statements, agreements and rules Set forth in these terms, you hereby acknowledge and Agree that you have not relied upon any other statement Or agreement, whether written or oral, with respect to Your use and access of Smart Bull services. Without Limiting the foregoing, you hereby understand and agree That Smart Bull will not be liable for any losses or Damages arising out of or relating to:</p>
                                        <p>A. Any Inaccuracy, Defect or Omission of Digital Assets Price Data</p>
                                        <p>B. Any Error or Delay in the Transmission of Such Data</p>
                                        <p>C. Interruption in Any Such Data</p>
                                        <p>D. Regular or Unscheduled Maintenance Carried Out by Smart Bull and Service Interruption and Change Resulting From Such Maintenance</p>
                                        <p>E. Any Damages Incurred by Other Users’ Actions, Omissions or Violation of These Terms</p>
                                        <p>F. Any Damage Caused by Illegal Actions of Other Third Parties or Actions Without Authorized by Smart Bull</p>
                                        <p>G. Other Exemptions Mentioned in Disclaimers and Platform Rules Issued by Smart Bull</p>
                                        <p>The disclaimer of implied warranties contained herein May not apply if and to the extent it is prohibited by Applicable law of the jurisdiction in which you reside.</p>

                                        <h5>3. Indemnification</h5>
                                        <p>You agree to indemnify and hold harmless Smart Bull' Operators, their affiliates, contractors, licensors, and their respective directors, officers, employees and agents from and against any claims, actions, proceedings, investigations, demands, suits, costs, expenses and damages (including attorneys’ fees, fines or penalties imposed by any regulatory authority) arising out of or related to:</p>
                                        <p>a. Your use of, or conduct in connection with, Smart Bull Services,</p>
                                        <p>b. Your breach or our enforcement of these Terms, or</p>
                                        <p>c. Your violation of any applicable law, regulation, or rights of any third party during your use of Smart Bull Services.</p>
                                        <p>You are obligated to indemnify Smart Bull Operators, their affiliates, contractors, licensors, and their respective directors, officers, employees or agents pursuant to these Terms, Smart Bull will have the right, in its sole discretion, to control any action or proceeding and to determine whether Smart Bull wishes to settle, and if so, on what terms.</p>

                                        <h4>V. Announcements</h4>
                                        <p>Please be aware that all official announcements, news, promotions, competitions and airdrops will be listed on (Announcements). Users Undertake to refer to these materials regularly and Promptly. Smart Bull will not be held liable or responsible In any manner of compensation should users incur personal Losses arising from ignorance or negligence of the Announcements.</p>

                                        <h4>VI. Termination of Agreement</h4>

                                        <h5>1. Suspension of Smart Bull Accounts</h5>
                                        <p>You acknowledge that Smart Bull has the right to immediately suspend your Smart Bull Account, freeze or lock the Digital Assets or funds in all related accounts (including those owned by affiliated entities), and suspend your access to Smart Bull for any reason, including suspected violations of these Terms, our Privacy Policy, or applicable laws and regulations. Smart Bull shall not be liable for any permanent or temporary modifications to your Smart Bull Account or suspension/termination of your access to Smart Bull Services. Smart Bull may retain and use transaction data or other information related to such accounts. Account controls may also be applied in cases where the Smart Bull Account is subject to a governmental proceeding, criminal investigation, or other pending litigation, unusual activities, unauthorized access, or court orders/regulatory commands.</p>

                                        <h5>2. Cancellation of Smart Bull Accounts</h5>
                                        <p>Smart Bull reserves the right to terminate these Terms by canceling your Smart Bull Account and permanently freezing (canceling) its authorizations on Smart Bull. Such termination may occur if you re-register under another person's name, provide untruthful, inaccurate, outdated, or incomplete information, reject the amended Terms, request termination of Smart Bull Services, or for other reasons deemed suitable by Smart Bull. Upon termination, account and transactional information that meets data retention standards will be securely stored for five years. Additionally, any unfinished transactions during the account termination process may be notified to your counterparty. If informed of stolen or unlawfully possessed Digital Assets or funds, Smart Bull may place an administrative hold on the affected funds and Account until the dispute is resolved.</p>

                                        <h5>3. Remaining Funds after Smart Bull Account Termination</h5>
                                        <p>After closing or withdrawing a Smart Bull Account, any remaining account balance (including charges and liabilities owed to Smart Bull) becomes payable immediately to Smart Bull. Users have five business days to withdraw all Digital Assets or funds from the account after clearing outstanding charges.</p>

                                        <h5>4. Remaining Funds after Smart Bull Account Termination Due to Fraud, Violation of Law, or Violation of These Terms</h5>
                                        <p>In cases of Account suspension/closure due to fraud investigations, violation of law, or these Terms, Smart Bull retains full custody of Digital Assets, funds, and User data/information, which may be turned over to governmental authorities.</p>

                                        <h5>5. Dormant Accounts</h5>
                                        <p>If you fail to close open positions and withdraw Digital Assets from your Smart Bull Account within 30 days of receiving a written notice, Smart Bull may deem your Account as dormant. In its absolute discretion and without prior notice, Smart Bull may take actions such as closing positions, converting Digital Assets, transferring the dormant Account to an affiliate or third-party custodian, charging a dormant Account fee, or closing the dormant Account. Smart Bull shall not be liable for any loss or expense incurred as a result, except in cases of fraud or willful default by Smart Bull. Dormant Accounts cannot be reactivated; users must register a new Smart Bull Account if they wish to continue using Smart Bull Services.</p>
                                        <p>a. Deem your Smart Bull account as a dormant account;</p>
                                        <p>b. Close any open positions in any Smart Bull products;</p>
                                        <p>c. Convert the Digital Assets to a different type of Digital Asset (e.g., from BTC to BUSD). For the avoidance of doubt, none of the Smart Bull Operators shall be liable for any loss of profit, tax obligations or any other loss, damage or expense incurred by you resulting from such conversion;</p>
                                        <p>d. Transfer such dormant account (including any Digital Assets contained therein) to an affiliate of the Smart Bull Operators, any third-party custodian or an isolated wallet where it is deemed reasonably necessary by Smart Bull to do so. In the event that such transfer has taken place, you have the right to retrieve your digital assets from subject to satisfying Smart Bull’ verification requirements, including completing KYC;</p>
                                        <p>e. Charge a dormant account fee to cover the cost of maintaining the assets by the Smart Bull Operators, its affiliates or any third-party and such fee shall be withdrawn directly from the dormant account on a monthly basis; and</p>
                                        <p>f. Close a dormant account at any time, and Smart Bull will not be liable for any loss, damage or expense incurred by you as a result of the closure of a dormant account unless there was fraud or willful default by Smart Bull. After a dormant account is closed, it cannot be reactivated by you (i.e. you will need to register a new Smart Bull account if you wish to continue to use Smart Bull Services).</p>

                                        <h4>VII. No Financial Advice</h4>
                                        <p>Smart Bull is not your broker, intermediary, agent, or advisor and has no fiduciary relationship or obligation to you in connection with any trades or other decisions or activities effected by you using Smart Bull Services. No communication or information provided to you by Smart Bull is intended as, or shall be considered or construed as, investment advice, financial advice, trading advice, or any other sort of advice. Unless otherwise specified in these Terms, all trades are executed automatically, based on the parameters of your order instructions and in accordance with posted trade execution procedures, and you are solely responsible for determining whether any investment, investment strategy or related transaction is appropriate for you according to your personal investment objectives, financial circumstances and risk tolerance, and you shall be solely responsible for any loss or liability therefrom. You should consult legal or tax professionals regarding your specific situation. Smart Bull does not recommend that any Digital Asset should be bought, earned, sold, or held by you. Before making the decision to buy, sell or hold any Digital Asset, you should conduct your own due diligence and consult your financial advisors prior to making any investment decision. Smart Bull will not be held responsible for the decisions you make to buy, sell, or hold Digital Asset based on the information provided by Smart Bull.</p>

                                        <h4>VIII. Compliance with Local Laws</h4>
                                        <p>It is Users’ responsibility to abide by local laws in relation to the legal usage of Smart Bull Services in their local jurisdiction as well as other laws and regulations applicable to Users. Users must also factor, to the extent of their local laws all aspects of taxation, the withholding, collection, reporting and remittance to their appropriate tax authorities. all users of Smart Bull services acknowledge and declare that their funds come from legitimate sources and do not originate from illegal activities; users agree that Smart Bull will require them to provide or otherwise collect the necessary information and materials as per relevant laws or government orders to verify the legality of the sources and use of their funds. Smart Bull maintains a stance of cooperation with law enforcement authorities globally and will not hesitate to seize, freeze, terminate Users’ accounts and funds which are flagged out or investigated by legal mandate.</p>

                                        <h4>IX. Privacy Policy</h4>
                                        <p>Access to Smart Bull Services will require the submission of certain personally identifiable information. Please review Smart Bull' Privacy Policy at (PrivacyPolicy) for a summary of guidelines regarding the collection and use of personally identifiable information.</p>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </main>
    );
}

export default Home;
