import React, { useEffect } from "react";
import Home_header from "./Home_header";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import { Bars } from "react-loader-spinner";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { postMethod } from "../core/service/common.api";
import { env } from "../core/service/envconfig";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import { Link } from "react-router-dom";

const Kyc = () => {
  const options = ["one", "two", "three"];

  const [idproof, setidproof, idproofref] = useState("");
  const [valididproof, setvalididproof] = useState(0);
  const [idproofLoad, setidproofLoad] = useState(false);

  const [addressProof, setaddressProof, addressProofref] = useState("");
  const [validaddressProof, setvalidaddressProof] = useState(0);
  const [addressProofLoad, setaddressProofLoad] = useState(false);

  const [addresIDFront, setaddresIDFront] = useState("");
  const [validaddresIDFront, setvalidaddresIDFront] = useState(0);
  const [addresIDFrontLoad, setaddresIDFrontLoad] = useState(false);

  const [addresIDBack, setaddresIDBack] = useState("");
  const [validaddresIDBack, setvalidaddresIDBack] = useState(0);
  const [addresIDBackLoad, setaddresIDBackLoad] = useState(false);

  const [photoproof, setphotoproof, photoproofref] = useState("");
  const [photoproofname, setphotoproofname, photoproofnameref] = useState("");
  const [idproofname, setidproofname, idproofnameref] = useState("");
  const [addressproofname, setaddressproofname, addressproofnameref] =
    useState("");

  const [validphotoproof, setvalidphotoproof] = useState(0);
  const [photoproofLoad, setphotoproofLoad] = useState(false);
  const [getKYCData, setgetKYCData] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [kycLoader, setkycLoader] = useState(false);
  const [user, Setuser] = useState("");

  const value = {
    idNumber: "",
    AadhaarNumber: "",
  };
  const [formValue, setformValue] = useState(value);

  const imageUpload = (type, val) => {
    try {
      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg "
        );
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
      } else {
        type == "idproof"
          ? setidproofLoad(true)
          : type == "addressProof"
          ? setaddressProofLoad(true)
          : type == "addressProofFront"
          ? setaddresIDFrontLoad(true)
          : type == "addressProofBack"
          ? setaddresIDBackLoad(true)
          : setphotoproofLoad(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            if (type == "idproof") {
              setidproofLoad(false);
              setvalididproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalididproof(1);
              }

              setidproof(data.secure_url);
              setidproofname(val.name);
            }
            if (type == "addressProof") {
              setaddressProofLoad(false);
              setvalidaddressProof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidaddressProof(1);
              }
              setaddressproofname(val.name);

              setaddressProof(data.secure_url);
            }
            if (type == "photoproof") {
              setphotoproofLoad(false);
              setvalidphotoproof(2);
              if (
                fileExtension == "pdf" ||
                fileExtension == "odt" ||
                fileExtension == "doc"
              ) {
                setvalidphotoproof(1);
              }
              setphotoproofname(val.name);

              setphotoproof(data.secure_url);
            }
          })
          .catch((err) => {
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      toast.error("Please try again later");
    }
  };

  const cancelData = () => {
    try {
      setphotoproofname("");
      setidproofname("");
      setaddressproofname("");
      setidproof("");
      setaddressProof("");
      setphotoproof("");
      setvalididproof(0);
      setvalidaddressProof(0);
      setvalidphotoproof(0);
    } catch (error) {}
  };
  const cancelidproof = () => {
    try {
      setidproofname("");
      setidproof("");
      setvalididproof(0);
    } catch (error) {}
  };
  const canceladdressproof = () => {
    try {
      setaddressproofname("");
      setaddressProof("");
      setvalidaddressProof(0);
    } catch (error) {}
  };
  const cancelphotoproof = () => {
    try {
      setphotoproofname("");
      setphotoproof("");
      setvalidphotoproof(0);
    } catch (error) {}
  };

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const uploadKYC = async () => {
    try {
      if (
        idproofref.current != "" &&
        addressProofref.current != "" &&
        photoproofref.current != ""
      ) {
        var obj = {
          addressProof: idproofref.current,
          idproof: addressProofref.current,
          photoproof: photoproofref.current,
        };

        var data = {
          apiUrl: apiService.savekyc,
          payload: obj,
        };
        setkycLoader(true);
        var resp = await postMethod(data);
        if (resp.status) {
          setkycLoader(false);
          getKyc();
          showSuccessToast(resp.Message);
        } else {
          showErrorToast(resp.Message);
          setkycLoader(false);
        }
      } else {
        showErrorToast("Please give all proof");
      }
    } catch (error) {}
  };

  const getKyc = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYC,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        Setuser(resp.datas.userDetails);
        if (resp.datas.kycDetails) {
          setvalididproof(2);
          setvalidaddressProof(2);
          setvalidaddresIDFront(2);
          setvalidaddresIDBack(2);
          setvalidphotoproof(2);
        }
        if (Object.keys(resp.datas.kycDetails).length > 0) {
          var kycData = resp.datas.kycDetails;

          setgetKYCData(kycData);
          setidproof(kycData.proof1);

          formValue.idNumber = kycData.IdNumber;
          setaddressProof(kycData.proof2);
          setaddresIDFront(kycData.proof3);
          setaddresIDBack(kycData.proof4);
          setphotoproof(kycData.proof3);
          var filetype_front = kycData.proof1.split(".").pop().trim();
          var filetype_back = kycData.proof2.split(".").pop().trim();
          var filetype_photoproof = kycData.proof3.split(".").pop().trim();
          if (
            filetype_front == "pdf" ||
            filetype_front == "doc" ||
            filetype_front == "docx"
          ) {
            setvalididproof(1);
          }
          if (
            filetype_back == "pdf" ||
            filetype_back == "doc" ||
            filetype_back == "docx"
          ) {
            setvalidaddressProof(1);
          }
          if (
            filetype_photoproof == "pdf" ||
            filetype_photoproof == "doc" ||
            filetype_photoproof == "docx"
          ) {
            setvalidphotoproof(1);
          }

          if (kycData.kycStatus == 3) {
            setvalididproof(2);
            setvalidaddressProof(2);
            setvalidphotoproof(2);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getKyc();
  }, [0]);

  const getValue = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setformValue(formData);
  };

  return (
    <main className="kyc_main">
      <div>
        <Home_header />
      </div>

      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <section className="kyc_section px-3">
            <div className="container">
              <div className="kyc_title">
                <h2>Individual verification</h2>
                {/* <p>
              Status: <span>verified</span>
            </p> */}
                {getKYCData.kycStatus == 3 ? (
                  <p>
                    Status :{" "}
                    <span className="kyctextcolorreject"> Rejected</span>
                  </p>
                ) : getKYCData.kycStatus == 2 ? (
                  <p>
                    Status :{" "}
                    <span className="kyctextcolorpending"> Pending</span>
                  </p>
                ) : getKYCData.kycStatus == 1 ? (
                  <p>
                    Status :{" "}
                    <span className="kyctextcolorverified"> Verified</span>
                  </p>
                ) : (
                  <p>
                    Status :{" "}
                    <span className="kyctextcolorreject"> Not Upload</span>
                  </p>
                )}
              </div>
              {/* <div className="kyc_border"></div> */}
              <div className="row">
                <div className="col-lg-8">
                  <div className="col-lg-12">
                    <div className="standard_box">
                      <h3>Standard Identity Verification</h3>
                      <p>
                        Verify your identity with our KYC process to unlock
                        enhanced features and ensure compliance. Submit your ID,
                        proof of address, and a selfie for verification. This
                        protects your account and keeps our platform secure for
                        all users. Complete KYC today for full access and peace
                        of mind.
                      </p>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                      >
                        Approve KYC
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="acc-feature_text">Account Features</div>
                    <div className="acc-feature_card">
                      <div className="acc-feature_title">
                        <p>Features</p>
                        <p>
                          Unverified <span>Current</span>
                        </p>
                        <p>Verified</p>
                      </div>
                      <div className="acc-feature_content">
                        <p>Withdrawals</p>
                        <p>0 USDT</p>
                        <p className="font-blue">999,999 USDT</p>
                      </div>
                      <div className="kyc_border"></div>
                      <div className="acc-feature_content">
                        <p>Trading</p>
                        <p>
                          {" "}
                          <i class="fa-solid fa-xmark"></i>
                        </p>
                        <p>
                          <i class="fa-solid fa-check"></i>
                        </p>
                      </div>
                      <div className="kyc_border"></div>
                      <div className="acc-feature_content">
                        <p>Withdrawals</p>
                        <p className="xmark">
                          <i class="fa-solid fa-xmark"></i>
                        </p>
                        <p>
                          {" "}
                          <i class="fa-solid fa-check"></i>
                        </p>
                      </div>
                      <div className="kyc_border"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <section className="faq-section">
                    <div className="faq_list">
                      <h3>FAQ</h3>
                      <div className="Accordion">
                        <Accordion className="Accordionfaq">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            className="Accordionhead"
                            id="panel1-header"
                          >
                            1. What is identity verification?
                          </AccordionSummary>
                          <AccordionDetails className="Accordionans">
                            Identity verification is the process of confirming
                            that a person's identity matches the information
                            provided.
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="Accordionfaq">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            2. Why do I need to complete identity verification?
                          </AccordionSummary>
                          <AccordionDetails>
                            This helps prevent fraud and ensures that services
                            are provided to legitimate individuals.
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="Accordionfaq">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                          >
                            3. Which documents and information are required for
                            identity verification?
                          </AccordionSummary>
                          <AccordionDetails>
                            The verification process typically involves
                            providing personal information along with supporting
                            documents for a national ID, address proof, and
                            photo proof.
                          </AccordionDetails>
                        </Accordion>
                        <Accordion className="Accordionfaq">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                          >
                            4. Where can I find help for issues regarding
                            identity verification?
                          </AccordionSummary>
                          <AccordionDetails>
                            Contact the exchange's customer support. Use this
                            link.
                            <Link to="/support">
                              {" "}
                              https://smartbull.io/support
                            </Link>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>

          {/*  */}
          {/* <!-- Button trigger modal --> */}

          {/* <!-- Modal --> */}
          <div
            class="modal fade bd-example-modal-lg"
            id="myModal"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content select_coin">
                <div class="modal-header kyc_modal_header">
                  <h4 class="modal-title ml-0">Identity Verification</h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    x
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body pt-0">
                  {getKYCData.kycStatus == 3 ? (
                    <div className="col-lg-12 p-0">
                      <label className="lable_KYC kyctextcolorreject">
                        {" "}
                        {getKYCData.rejectReson}{" "}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="check_radio">
                        <ul class="nav nav-tabs">
                          <li>
                            <a data-toggle="tab" href="#menu2">
                              <div className="image_div">
                                <div className="cicler">
                                  <img
                                    src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                    className=""
                                  />
                                </div>
                                National ID
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="upload">
                        <div class="mb-6 pt-4">
                          <div class="formbold-mb-5 formbold-file-input">
                            {user.kycstatus == 0 ||
                            user.kycstatus == 3 ||
                            valididproof == 0 ? (
                              <input
                                type="file"
                                className="fileinput"
                                name="image"
                                onChange={(e) =>
                                  imageUpload("idproof", e.target.files[0])
                                }
                                id="file1"
                              />
                            ) : (
                              ""
                            )}

                            <label for="file1" className="file123">
                              {idproofLoad == false ? (
                                valididproof == 0 ? (
                                  <div>
                                    <span class="formbold-drop-file">
                                      <img
                                        className="cursor"
                                        src={require("../images/dashboard/proof_id.png")}
                                      />
                                    </span>
                                  </div>
                                ) : valididproof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={idproofref.current}
                                    className="img-fluid"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div
                                  class="spinner-border spinner_kyc "
                                  role="status"
                                >
                                  <i class="fa fa-spinner d-none"></i>{" "}
                                </div>
                              )}

                              {/* {idproofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>

                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                            </label>
                          </div>

                          {idproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div class="formbold-file-list formbold-mb-5">
                              <div class="formbold-file-item d-flex justify-content-between">
                                <span class="formbold-file-name">
                                  {" "}
                                  {idproofnameref.current}{" "}
                                </span>
                                <button onClick={cancelidproof}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="check_radio">
                        {/* <label className="lable_KYC">Select Verification Type</label> */}
                        <ul class="nav nav-tabs">
                          <li>
                            <a data-toggle="tab" href="#menu2">
                              <div className="image_div">
                                <div className="cicler">
                                  <img
                                    src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                    className=""
                                  />
                                </div>
                                Proof Of Address
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="upload">
                        <div class="mb-6 pt-4">
                          <div class="formbold-mb-5 formbold-file-input">
                            {user.kycstatus == 0 ||
                            user.kycstatus == 3 ||
                            valididproof == 0 ? (
                              <input
                                type="file"
                                className="fileinput"
                                name="image"
                                onChange={(e) =>
                                  imageUpload("addressProof", e.target.files[0])
                                }
                                id="file2"
                              />
                            ) : (
                              ""
                            )}

                            <label for="file2" class="file123">
                              {addressProofLoad == false ? (
                                validaddressProof == 0 ? (
                                  <div>
                                    <span class="formbold-drop-file">
                                      <img
                                        className="cursor"
                                        src={require("../images/dashboard/proof_id.png")}
                                      />
                                    </span>
                                  </div>
                                ) : validaddressProof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={addressProofref.current}
                                    className="img-fluid"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div
                                  class="spinner-border spinner_kyc "
                                  role="status"
                                >
                                  <i class="fa fa-spinner d-none"></i>{" "}
                                </div>
                              )}

                              {/* {addressProofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>
                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                            </label>
                          </div>

                          {addressproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div class="formbold-file-list formbold-mb-5">
                              <div class="formbold-file-item d-flex justify-content-between">
                                <span class="formbold-file-name">
                                  {" "}
                                  {addressproofnameref.current}{" "}
                                </span>
                                <button onClick={canceladdressproof}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="check_radio">
                        {/* <label className="lable_KYC">Select Verification Type</label> */}
                        <ul class="nav nav-tabs">
                          <li>
                            <a data-toggle="tab" href="#menu2">
                              <div className="image_div">
                                <div className="cicler">
                                  <img
                                    src="https://assets.staticimg.com/g-biz/externals/2022-06-01/43ef79d27324eaf9.svg"
                                    className=""
                                  />
                                </div>
                                <div>
                                  <h6 className="m-0"> Photo Proof</h6>
                                  <span className="kcspan">
                                    (Take Selfie
                                    <br /> with your document)
                                  </span>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="upload">
                        <div class="mb-6 pt-4">
                          <div class="formbold-mb-5 formbold-file-input">
                            {user.kycstatus == 0 ||
                            user.kycstatus == 3 ||
                            valididproof == 0 ? (
                              <input
                                type="file"
                                className="fileinput"
                                name="image"
                                onChange={(e) =>
                                  imageUpload("photoproof", e.target.files[0])
                                }
                                id="file3"
                              />
                            ) : (
                              ""
                            )}

                            <label for="file3" class="file123">
                              {photoproofLoad == false ? (
                                validphotoproof == 0 ? (
                                  <div>
                                    <span class="formbold-drop-file">
                                      <img
                                        className="cursor"
                                        src={require("../images/dashboard/proof_id.png")}
                                      />
                                    </span>
                                  </div>
                                ) : validphotoproof == 1 ? (
                                  <span className="">
                                    <i class="bi bi-file-earmark-bar-graph"></i>
                                  </span>
                                ) : (
                                  <img
                                    src={photoproofref.current}
                                    className="img-fluid"
                                    alt="National Id Front"
                                  />
                                )
                              ) : (
                                <div
                                  class="spinner-border spinner_kyc "
                                  role="status"
                                >
                                  <i class="fa fa-spinner d-none"></i>{" "}
                                </div>
                              )}

                              {/* {photoproofref.current == "" ? (
                            <div>
                              <span class="formbold-drop-file">
                                {" "}
                                Drop files here{" "}
                              </span>
                              <span class="formbold-or"> Or </span>
                              <span class="formbold-browse"> Browse </span>
                            </div>
                          ) : (
                            ""
                          )} */}
                            </label>
                          </div>

                          {photoproofnameref.current == "" ? (
                            ""
                          ) : (
                            <div class="formbold-file-list formbold-mb-5">
                              <div class="formbold-file-item d-flex justify-content-between">
                                <span class="formbold-file-name">
                                  {" "}
                                  {photoproofnameref.current}{" "}
                                </span>
                                <button onClick={cancelphotoproof}>
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 0.279338C0.651787 -0.0931121 1.25565 -0.0931121 1.6281 0.279338L9.72066 8.3719C10.0931 8.74435 10.0931 9.34821 9.72066 9.72066C9.34821 10.0931 8.74435 10.0931 8.3719 9.72066L0.279337 1.6281C-0.0931125 1.25565 -0.0931125 0.651788 0.279337 0.279338Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M0.279337 9.72066C-0.0931125 9.34821 -0.0931125 8.74435 0.279337 8.3719L8.3719 0.279338C8.74435 -0.0931127 9.34821 -0.0931123 9.72066 0.279338C10.0931 0.651787 10.0931 1.25565 9.72066 1.6281L1.6281 9.72066C1.25565 10.0931 0.651787 10.0931 0.279337 9.72066Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  {getKYCData.kycStatus == 2 || getKYCData.kycStatus == 1 ? (
                    ""
                  ) : (
                    <div>
                      <Button
                        className="mx-2 primary-btn"
                        data-bs-dismiss="modal"
                        onClick={cancelData}
                      >
                        Cancel
                      </Button>
                      {kycLoader == false ? (
                        <Button
                          data-bs-dismiss="modal"
                          onClick={uploadKYC}
                          className="primary-btn"
                        >
                          Upload
                        </Button>
                      ) : (
                        <Button className="primary-btn" data-bs-dismiss="modal">
                          Loading...
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  );
};

export default Kyc;
