import React, { useEffect } from "react";
import Header from "./Home_header";
import { Button } from "@mui/material";

import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";


function Home() {
  const navigate = useNavigate();

  const initialFormValue = {
    email: "",
  };

  const initialFormValue1 = {
    password: "",
    confirmPassword: "",
  };

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [activeStatus, seractiveStatus] = useState(false);
  const [dpliutl, setdpliutl] = useState("");
  const [validationnErr1, setvalidationnErr1] = useState("");
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [siteLoader, setSiteLoader] = useState(false);

  const { email } = formValue;
  const { password, confirmPassword } = formValue1;

  useEffect(() => {
    linkverify();
  }, [0]);

  const linkverify = async (values) => {
    var dpliutl = window.location.href.split("_")[1];
    if (dpliutl) {
      setdpliutl(dpliutl);
      var obj = {
        link: dpliutl,
      };
      var data = {
        apiUrl: apiService.verifyForgotpasslink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        seractiveStatus(true);
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };


  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    validate(formData);
  };

  const handleChange1 = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData1 = { ...formValue1, ...{ [name]: value } };
    setFormValue1(formData1);
    validate1(formData1);
  };

  const validate = async (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]*[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email =
        "Invalid email address! Email must contain at least one character.";
      setemailValidate(true);
    } else if (values.email.length > 254) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else {
      setemailValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const validate1 = async (values) => {
    const errors1 = {};

    if (!values.password) {
      errors1.password = "Password is a required field";
      setpasswordValidate(true);
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors1.password = "Password should not below 5 above 25 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors1.password = "Please enter at least one special character !";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors1.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors1.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else {
      setconfirmPasswordValidate(false);
    }

    setvalidationnErr1(errors1);
    return errors1;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.email != "" && emailValidateref.current == false) {
      var obj = {
        email: formValue.email,
      };
      var data = {
        apiUrl: apiService.changepswdlink,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      console.log(resp, "==-=-resp");
      setbuttonLoader(false);
      setFormValue(initialFormValue);
      if (resp.status) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    }
  };

  const formSubmitchange = async () => {
    validate1(formValue1);
    if (
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false
    ) {
      var obj = {
        password: formValue1.password,
        confimPassword: formValue1.confirmPassword,
        id: dpliutl,
      };

      console.log(obj, "=-=-=-=-=-=-=-==-=");
      var data = {
        apiUrl: apiService.resetpassword,
        payload: obj,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      setFormValue1(initialFormValue1);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/login");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form minheight100">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col-lg-5 smart_login_row">
                    {activeStatus == false ? (
                      <div className="card_login mt25">
                        <h3>Forget Password</h3>
                        <div>
                          <div class="form_login_input">
                            <label className="mb-3">
                              Enter your email to reset your password
                            </label>
                            <input
                              type="text"
                              autocomplete="off"
                              class="form-control"
                              placeholder="Email address"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          </div>
                          {emailValidateref.current == true ? (
                            <p className="errorcss"> {validationnErr.email} </p>
                          ) : (
                            ""
                          )}

                          <div class="form_login_input mt-3">
                            {buttonLoader == false ? (
                              <Button onClick={formSubmit}>Submit</Button>
                            ) : (
                              <Button>Loading...</Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="card_login mt25">
                        <h3 className="mb-2">Reset Password</h3>
                        <div>
                          <label className="lable_text">New Password</label>
                          <div class="form_login_input">
                            <div className="flex_input_posion">
                              <input
                                type={inputType}
                                autocomplete="off"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={handleChange1}
                              />
                              <div className="eye_content">
                                {passHide == false ? (
                                  <i
                                    className="bi bi-eye-slash-fill"
                                    onClick={() => passwordHide("hide")}
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-eye-fill"
                                    onClick={() => passwordHide("show")}
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            {passwordValidate == true ? (
                              <p className="errorcss">
                                {" "}
                                {validationnErr1.password}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <label className="lable_text">Confirm Password</label>

                          <div class="form_login_input">
                            <div className="flex_input_posion">
                              <input
                                type={inputTypeconf}
                                autocomplete="off"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={confirmPassword}
                                onChange={handleChange1}
                              />
                              <div className="eye_content">
                                {passHidconf == false ? (
                                  <i
                                    className="bi bi-eye-slash-fill"
                                    onClick={() => passwordHideconf("hide")}
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-eye-fill"
                                    onClick={() => passwordHideconf("show")}
                                  ></i>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            {confirmPasswordValidate == true ? (
                              <p className="errorcss">
                                {" "}
                                {validationnErr1.confirmPassword}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>

                          <div class="form_login_input mt-4">
                            {buttonLoader == false ? (
                              <Button onClick={formSubmitchange}  className="mt-5">Change Password</Button>
                            ) : (
                              <Button>Loading...</Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
