import React, { useEffect } from "react";
import Header from "./Home_header";
import { Button } from "@mui/material";
import Select from "react-dropdown-select";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import "../../src/App.css";
import useState from "react-usestateref";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

function Home() {
  const options = [
    {
      value: 1,
      label: "Leanne Graham",
    },
    {
      value: 2,
      label: "Ervin Howell",
    },
  ];
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const initialFormValue = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    RefferedBy: "",

    // isTerms: ""
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");
  const [RefferedBy, setRefferedBy] = useState("");

  const { name, email, password, confirmPassword } = formValue;
  const [siteLoader, setSiteLoader] = useState(false);

  const navigate = useNavigate();

  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = (values) => {
    let errors = {};
    const username = values.email.split('@')[0];

    if (!values.name) {
      errors.name = "Name is a required field !";
      setnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.name)) {
      errors.name = "Username must start with a letter !";
      setnameValidate(true);
    } else if (values.name.length < 3 || values.name.length > 25) {
      errors.name = "Name must have an  3 to 25 characters !";
      setnameValidate(true);
    } else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
      errors.name =
        "Username can only contain letters, numbers, and underscores !";
      setnameValidate(true);
    } else if (!values.email) {
      setnameValidate(false);
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (username.length < 4 || username.length > 30) {
      errors.email = "Email Username must be between 4-30 characters long";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email = " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    } else if (values.email.length > 50) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else if (values.password == "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "password is required !";
    } else if (values.password.length < 5 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = "Password should not below 5 above 25 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm password is a required field";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else if (!Terms) {
      setconfirmPasswordValidate(false);
      errors.terms = "Terms is a required field ";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setemailValidate(false);
      setnameValidate(false);

      setTermsValidation(false);
    }
    setvalidationnErr(errors);

    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    // event.preventDefault();
    let errros = validate(formValue);

    console.log(errros, "errros---");
    // var obj = {
    //   FormValue : formValue,
    //   UUID : createUid
    // }
    if (RefferedBy == "" || RefferedBy == undefined) {
      // console.log("Error");
      formValue["ReferelBy"] = "";
    } else {
      formValue["ReferelBy"] = RefferedBy;
      // console.log(RefferedBy, "success");
    }
    formValue["UUID"] = createdUuid;
    console.log(formValue, "=-=-=-formvalue=-=-");
    if (
      nameValidateref.current === false &&
      emailValidateref.current === false &&
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false &&
      Terms == true
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signup,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setFormValue(initialFormValue);
      localStorage.setItem("useremail", resp.email);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        navigate("/verify-email");
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  useEffect(() => {
    var currURL = window.location.href;
    var refferalId = currURL.split("invite=")[1];
    setRefferedBy(refferalId);
  }, []);
  return (
    <div className="header ">
      <Header />
      <main>
        <div className="login_form smart_register_page">
          <div className="container">
            {siteLoader == true ? (
              <i class="fa-solid fa-spinner fa-spin"></i>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col-lg-5 smart_login_row ">
                    <div className="card_login register_page_login_card">
                      <h3 className="mb-3">Create an account</h3>
                      <div>
                        <label className="lable_text">Name</label>
                        <div class="form_login_input">
                          <input
                            type="text"
                            name="name"
                            autocomplete="off"
                            minLength={3}
                            maxLength={25}
                            value={name}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Name"
                          />
                        </div>

                        {validationnErr && validationnErr.name && (
                          <p className="errorcss">{validationnErr.name}</p>
                        )}

                        <label className="lable_text">Email</label>
                        <div class="form_login_input">
                          <input
                            type="text"
                            name="email"
                            autocomplete="off"
                            value={email}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Email"
                          />
                        </div>

                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}

                        <label className="lable_text ">Password</label>
                        <div class="form_login_input password_border">
                          <input
                            type={inputType}
                            name="password"
                            autocomplete="off"
                            value={password}
                            minLength={6}
                            maxLength={15}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Password"
                          />

                          <div className="eye_content">
                            {passHide == false ? (
                              <i
                                className="fa-regular fa-eye-slash icons"
                                onClick={() => passwordHide("hide")}
                              ></i>
                            ) : (
                              <i
                                className="fa-regular fa-eye icons"
                                onClick={() => passwordHide("show")}
                              ></i>
                            )}
                          </div>
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}

                        <label className="lable_text">Confirm Password</label>
                        <div class="form_login_input password_border">
                          <input
                            type={inputTypeconf}
                            autocomplete="off"
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={6}
                            maxLength={15}
                            onChange={handleChange}
                            class="form-control"
                            placeholder="Confirm Password"
                          />

                          <div className="eye_content">
                            {passHidconf == false ? (
                              <i
                                className="fa-regular fa-eye-slash icons"
                                onClick={() => passwordHideconf("hide")}
                              ></i>
                            ) : (
                              <i
                                className="fa-regular fa-eye icons"
                                onClick={() => passwordHideconf("show")}
                              ></i>
                            )}
                          </div>
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}

                      

                        <div class="custom-control custom-checkbox mb-3 mt-4">
                          <input
                            type="checkbox"
                            value={isChecked}
                            name="check"
                            onChange={handleTerms}
                            class="custom-control-input"
                            id="customCheck"
                          />
                          <label class="custom-control-label" for="customCheck">
                            I agree to the{" "}
                            <Link to="/terms-conditions" target="_blank">
                              Terms and Condition
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy-policy" target="_blank">
                              Privacy Policy
                            </Link>
                            .
                          </label>


                        </div>
                        {termsValidation ? (
                          <p className="errorcss">Terms and Conditions are required</p>
                        ) : ""}

                        <div class="form_login_input">
                          {buttonLoader == false ? (
                            <Button onClick={formSubmit}>Sign up</Button>
                          ) : (
                            <Button>Loading...</Button>
                          )}
                        </div>
                      </div>
                      <p className="color_hss text-center mb-0 mt-3">
                        {" "}
                        Already have an account?
                        <Link to="/login"> Log In</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
