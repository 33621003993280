import React, { useEffect } from "react";
import useState from "react-usestateref";
import Home_header from "./Home_header";
import Accordion from "@mui/material/Accordion";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Moment from "moment";
const Deposit = () => {
 
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);

  useEffect(() => {
    gethomeCurrency();
  }, []);

  const navtradepage = async (symbol) => {
    window.location.href = `trade/${symbol}_USDT`;
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.homeCurrency,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      setcurrencylistData(resp.Message);
    } else {
    }
  };

  return (
    <main className="deposit_main_page">
      <section>
        <Home_header />
      </section>
      {siteLoader == true ? (
        <div className="dashboard_content_section loadercss">
          <Bars
            height="40"
            width="40"
            color="#1062fe"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
      <>
        <section className="deposit_history_tabel_section marketpage">
          <div className="container">
            <div className="refresh">
              <div className="deposit_history_title mt-5">Market Overview</div>
              <p></p>
            </div>
            <div className="table-responsive deposite_tabel_border market_over_tabel">
              <table className="table">
                <thead>
                  <tr className="tabel_bg">
                    <th scope="col" className="starts">
                      Name
                    </th>
                    <th scope="col">Price (USD)</th>
                    <th scope="col" className="ends">
                      Trade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currencylistData && currencylistData.length > 0 ? (
                    currencylistData.map((obj, i) => {
                      return (
                        <tr className="history_tabel_bottom_border">
                          <th scope="row">
                            <span class="icon-btc">
                              {" "}
                              <img src={obj.Currency_image} />
                            </span>
                            <span>{obj.currencyName}</span>
                          </th>

                          {obj.marketprice <= 0 ? (
                          <td className="text-danger">
                          
                            {obj.currencySymbol == "SHIB"
                              ? parseFloat(obj.estimatedValueInUSDT).toFixed(8)
                              : parseFloat(obj.estimatedValueInUSDT).toFixed(2)}
                          </td>) : (
                            <td className="text-success">
                          
                            {obj.currencySymbol == "SHIB"
                              ? parseFloat(obj.estimatedValueInUSDT).toFixed(8)
                              : parseFloat(obj.estimatedValueInUSDT).toFixed(2)}
                          </td>
                          ) }

                          <td onClick={() => navtradepage(obj.currencySymbol)} className="cursor-pointer">
                            Buy
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <div className="empty_data">
                          <div className="empty_data_img"></div>
                          <div className="no_records_text">
                            No Records Found{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </>)}
    </main>
  );
};

export default Deposit;
