import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer.js";
import Home_header from "./Home_header.js";

const LandingPage = () => {
  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <main className="smart_bull_landing_page">
      <section>
        <Home_header />
      </section>
      <section className="smart_hero_section">
        <div className="container">
          <div className="smart-hero-section-content">
            <p>Trade confidently on the</p>
            <h1>fastest-growing crypto exchange</h1>
            <h3>
              <span className="span-blue">Join us </span>for seamless crypto
              trading.
            </h3>
            <button className="reg-button">Register Now</button>
          </div>
        </div>
      </section>
      <section className="crypto-coin-slider-section">
        <Slider {...sliderSettings}>
          <div className="logo_client_img token-dev">
            <img
              src={require("../images/landing/btc-icon.webp")}
              alt="BTC Icon"
            />
            <h4>Bitcoin</h4>
            <p className="text-success">
              <i className="ri-arrow-up-s-fill"></i> 00.0%
            </p>
          </div>
          <div className="logo_client_img token-dev">
            <img
              src={require("../images/landing/ehth_icon.webp")}
              alt="BTC Icon"
            />
            <h4>Ethereum</h4>
            <p className="text-success">
              <i className="ri-arrow-up-s-fill"></i> 00.0%
            </p>
          </div>
          <div className="logo_client_img token-dev">
            <img
              src={require("../images/landing/dai-icon.webp")}
              alt="BTC Icon"
            />
            <h4>DAI</h4>
            <p className="text-success">
              <i className="ri-arrow-up-s-fill"></i> 00.0%
            </p>
          </div>
          <div className="logo_client_img token-dev">
            <img
              src={require("../images/landing/bnb_icon.webp")}
              alt="BTC Icon"
            />
            <h4>BNB</h4>
            <p className="text-danger">
              <i class="ri-arrow-down-s-fill"></i> 00.0%
            </p>
          </div>
          <div className="logo_client_img token-dev">
            <img src={require("../images/landing/doge.webp")} alt="BTC Icon" />
            <h4>DOGE</h4>
            <p className="text-danger">
              <i class="ri-arrow-down-s-fill"></i> 00.0%
            </p>
          </div>
        </Slider>
      </section>
      <section className="popular-cryptocurrencies-section">
        <div className="container">
          <div className="popular-cryptocurrencies">
            <h2>Popular Cryptocurrencies</h2>

            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  HOT LIST
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  NEW LIST
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">Volume</th>
                        <th scope="col">Volume</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Trade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/staked-icon.webp")}
                            />
                            <p>
                              Staked Ether <span>STETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/binance-icon.webp")}
                            />
                            <p>
                              Binance Coin <span>BNB</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/bitcoin-icon.webp")}
                            />
                            <p>
                              Bitcoin <span>BTC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/open-network-icon.webp")}
                            />
                            <p>
                              The Open Network <span>TONCOIN</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-sucess">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/ehhereum-icon.webp")}
                            />
                            <p>
                              Ethereum <span>ETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/solana-icon.webp")}
                            />
                            <p>
                              Solana <span>SOL</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/usd-icon.webp")}
                            />
                            <p>
                              USD Coin <span>USDC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Last Price</th>
                        <th scope="col">Volume</th>
                        <th scope="col">Volume</th>
                        <th scope="col">24h Change</th>
                        <th scope="col">Trade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/staked-icon.webp")}
                            />
                            <p>
                              Staked Ether <span>STETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/binance-icon.webp")}
                            />
                            <p>
                              Binance Coin <span>BNB</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/bitcoin-icon.webp")}
                            />
                            <p>
                              Bitcoin <span>BTC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-success">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/open-network-icon.webp")}
                            />
                            <p>
                              The Open Network <span>TONCOIN</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-sucess">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/ehhereum-icon.webp")}
                            />
                            <p>
                              Ethereum <span>ETH</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/solana-icon.webp")}
                            />
                            <p>
                              Solana <span>SOL</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-red.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="name-coin-item">
                            <img
                              src={require("../images/landing/usd-icon.webp")}
                            />
                            <p>
                              USD Coin <span>USDC</span>
                            </p>
                          </div>
                        </th>
                        <td>$3760.34</td>
                        <td>1245342.37</td>
                        <td>
                          <img
                            src={require("../images/landing/volume-green.webp")}
                          />
                        </td>
                        <td className="text-danger">+00.00</td>
                        <td className="trade-table-btn">
                          <button>Trade</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="build-your-crypto-section">
        <div className="container">
          <div className="build-your-crypto-content">
            <h2>Build Your Crypto Portfolio</h2>
            <p>Start your first trade with these easy steps.</p>
            <div className="row">
              <div className="col-lg-4">
                <div className="build-your-crypto-steps verify-before-line">
                  <img
                    src={require("../../src/images/landing/verify_icon.webp")}
                    alt="Verify Your Identity"
                  />
                  <h3>Verify Your Identity</h3>
                  <p>
                    Complete the identity verification
                    <br /> process to secure your account
                    <br /> and transactions.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="build-your-crypto-steps verify-before-line">
                  <img
                    src={require("../../src/images/landing/fund_icon.webp")}
                    alt="Fund Your Account"
                  />
                  <h3>Fund Your Account</h3>
                  <p>
                    Add funds to your crypto account to start
                    <br /> trading crypto. You can add funds with a<br />{" "}
                    variety of payment methods.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="build-your-crypto-steps">
                  <img
                    src={require("../../src/images/landing/trading_icon.webp")}
                    alt="Start Trading"
                  />
                  <h3>Start Trading</h3>
                  <p>
                    Start trading cryptocurrencies today.
                    <br /> Explore markets and execute trades with
                    <br /> ease on our user-friendly platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="salient-features-section">
        <div className="container">
          <div className="salient-features">
            <h2>Salient Features of Our Exchange</h2>
            <div className="row">
              <div className="col-lg-8 wide-range-asset-content">
                <div className="col-lg-8">
                  <div className="wide-range">
                    <img
                      src={require("../images/landing/wide_range_icon.webp")}
                      alt="Wide Range of Assets"
                    />
                    <h3>Wide Range of Assets</h3>
                    <p>
                      We provide access to a diverse selection of
                      cryptocurrencies, allowing users to trade various digital
                      assets and explore new investment opportunities.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="Wide-asset">
                    <img
                      src={require("../images/landing/wide_asset.webp")}
                      alt="Wide Asset"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="diversified-crypto">
                  <img
                    src={require("../images/landing/diversified_icon.webp")}
                    alt="Diversified Crypto Portfolio"
                  />
                  <h3>Diversified Crypto Portfolio</h3>
                  <p>
                    Diversify your crypto portfolio for stability and growth.
                    Spread investments across various cryptocurrencies for risk
                    management and potential returns
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/robust_icon.webp")}
                    alt="Robust Security Measures"
                  />
                  <h3>Robust Security Measures</h3>
                  <p>
                    We employ advanced security protocols to ensure the safety
                    of our users assets, including multi-factor authentication,
                    cold storage for the majority of funds, and regular security
                    audits.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/competitive_icon.webp")}
                    alt="Competitive Trading Fees"
                  />
                  <h3>Competitive Trading Fees</h3>
                  <p>
                    Our platform offers significantly lower trading fees
                    compared to other global exchanges. Users can easily earn
                    while buying, selling, and withdrawing funds.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="robust-security">
                  <img
                    src={require("../images/landing/fastest_support_icon.webp")}
                    alt="Fastest Customer Support"
                  />
                  <h3>Fastest Customer Support</h3>
                  <p>
                    Our dedicated customer support team is available around the
                    clock to assist users with any queries or issues they may
                    encounter, ensuring a smooth trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="crypto-trailblazer-section">
        <div className="container">
          <div className="crypto-trailblazer-content">
            <h2>
              Stay ahead and become a Crypto Trailblazer
              <br />
              with Our App!
            </h2>
            <div className="row">
              <div className="col-lg-7">
                <div className="crypto-trailblazer-banner">
                  <img
                    src={require("../images/landing/trailblazer_banner.webp")}
                    className="trailblazer_banner-img"
                    alt="Crypto Trailblazer"
                  />
                  <img
                    src={require("../images/landing/trailblazer_banner-bg.png")}
                    className="trailblazer_banner-bg"
                    alt="Crypto Trailblazer Background"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="trade-anytime-content">
                  <h3>
                    Trade Anytime,
                    <br />
                    Anywhere.
                  </h3>
                </div>
                <div className="google-play_and_appstore">
                  <div className="col-lg-6">
                    <div className="gplay-appstore">
                      <img
                        src={require("../images/landing/app_store_logo.webp")}
                        alt="App Store"
                      />
                      <img
                        src={require("../images/landing/gpay_logo.webp")}
                        alt="Google Play"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="Trade-qr">
                      <img
                        src={require("../images/landing/trade_qr_code.webp")}
                        alt="Trade QR Code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="start-your-crypto-section">
        <div className="container">
          <div className="start-your-crypto-content">
            <h2>Start Your Crypto Journey Now!</h2>
            <button className="reg-button">Get Started</button>
          </div>
        </div>
      </section>
      <section className="smart-footer">
        <Footer />
      </section>
    </main>
  );
};

export default LandingPage;
