import React from "react";

function PriceFooter() {
  return (
    <>
      <div>
        <div class="footer-sticky css-1bea9fq">
          <div class="css-1vrz22u">
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="9.667" width="1.67" height="2.5" fill="#0ECB81"></rect>
              <rect
                x="4.44446"
                y="5.667"
                width="1.66667"
                height="6.67"
                fill="#0ECB81"
              ></rect>
              <rect
                x="8.88892"
                y="3"
                width="1.66667"
                height="10.83"
                fill="#0ECB81"
              ></rect>
              <rect
                x="13.3333"
                width="1.66667"
                height="15"
                fill="#0ECB81"
              ></rect>
            </svg>
            <div class="css-19fju8h">Stable connection</div>
          </div>
          <div class="css-14fo47n">
            <div class="foot-line"></div>
            <div class="css-18shdj7">
              <div class="css-1aebr02">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="css-15gs5hm"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 6h-7.79a4.001 4.001 0 00-7.42 0H3v3h2.79a4.001 4.001 0 007.42 0H21V6zm-10.21 9H3v3h7.79a4.001 4.001 0 007.42 0H21v-3h-2.79a4.001 4.001 0 00-7.42 0z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div
                class="css-gv1gi9"
                data-popper-reference-hidden="false"
                data-popper-escaped="false"
                data-popper-placement="top-end"
              ></div>
            </div>
            <div class="listWrap css-7tgotf">
              <div class="container11 animation">
                <div class="css-fnk7g1">
                  <div class="previewItemWrap">
                    <div class="symbol">SOLUSDT</div>
                    <div class="change buy">+0.53%</div>
                    <div class="lastPrice">116.56</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BTCUSDT</div>
                    <div class="change buy">+1.48%</div>
                    <div class="lastPrice">52,231.10</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BNBUSDT</div>
                    <div class="change buy">+4.17%</div>
                    <div class="lastPrice">345.1</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">CKBUSDT</div>
                    <div class="change buy">+59.95%</div>
                    <div class="lastPrice">0.013740</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">ETHUSDT</div>
                    <div class="change buy">+1.68%</div>
                    <div class="lastPrice">2,794.40</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">AIUSDT</div>
                    <div class="change sell">-7.90%</div>
                    <div class="lastPrice">1.61171</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BAKEUSDT</div>
                    <div class="change buy">+7.92%</div>
                    <div class="lastPrice">0.4144</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">XRPUSDT</div>
                    <div class="change buy">+4.17%</div>
                    <div class="lastPrice">0.5542</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">VETUSDT</div>
                    <div class="change buy">+17.82%</div>
                    <div class="lastPrice">0.04153</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">ADAUSDT</div>
                    <div class="change buy">+2.60%</div>
                    <div class="lastPrice">0.5850</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">RIFUSDT</div>
                    <div class="change buy">+45.56%</div>
                    <div class="lastPrice">0.2262</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">USTCUSDT</div>
                    <div class="change buy">+10.68%</div>
                    <div class="lastPrice">0.02976035</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">LINKUSDT</div>
                    <div class="change sell">-1.18%</div>
                    <div class="lastPrice">20.166</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DOGEUSDT</div>
                    <div class="change buy">+3.20%</div>
                    <div class="lastPrice">0.08583</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">OMUSDT</div>
                    <div class="change sell">-0.18%</div>
                    <div class="lastPrice">0.27733</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">VTHOUSDT</div>
                    <div class="change buy">+49.27%</div>
                    <div class="lastPrice">0.004572</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">MATICUSDT</div>
                    <div class="change buy">+0.50%</div>
                    <div class="lastPrice">0.8920</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">TRXUSDT</div>
                    <div class="change buy">+1.90%</div>
                    <div class="lastPrice">0.13136</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">PEPEUSDT</div>
                    <div class="change buy">+13.51%</div>
                    <div class="lastPrice">0.00000126</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">PROMUSDT</div>
                    <div class="change sell">-10.56%</div>
                    <div class="lastPrice">11.558</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DYMUSDT</div>
                    <div class="change buy">+14.08%</div>
                    <div class="lastPrice">8.234</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">API3USDT</div>
                    <div class="change buy">+32.45%</div>
                    <div class="lastPrice">4.727</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DOTTRY</div>
                    <div class="change buy">+1.44%</div>
                    <div class="lastPrice">240.0</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">AGLDUSDT</div>
                    <div class="change buy">+3.90%</div>
                    <div class="lastPrice">1.359</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">STPTUSDT</div>
                    <div class="change buy">+0.79%</div>
                    <div class="lastPrice">0.05586</div>
                    <div class="foot-line"></div>
                  </div>
                </div>
                <div class="css-fnk7g1">
                  <div class="previewItemWrap">
                    <div class="symbol">SOLUSDT</div>
                    <div class="change buy">+0.53%</div>
                    <div class="lastPrice">116.56</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BTCUSDT</div>
                    <div class="change buy">+1.48%</div>
                    <div class="lastPrice">52,231.10</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BNBUSDT</div>
                    <div class="change buy">+4.17%</div>
                    <div class="lastPrice">345.1</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">CKBUSDT</div>
                    <div class="change buy">+59.95%</div>
                    <div class="lastPrice">0.013740</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">ETHUSDT</div>
                    <div class="change buy">+1.68%</div>
                    <div class="lastPrice">2,794.40</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">AIUSDT</div>
                    <div class="change sell">-7.90%</div>
                    <div class="lastPrice">1.61171</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">BAKEUSDT</div>
                    <div class="change buy">+7.92%</div>
                    <div class="lastPrice">0.4144</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">XRPUSDT</div>
                    <div class="change buy">+4.17%</div>
                    <div class="lastPrice">0.5542</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">VETUSDT</div>
                    <div class="change buy">+17.82%</div>
                    <div class="lastPrice">0.04153</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">ADAUSDT</div>
                    <div class="change buy">+2.60%</div>
                    <div class="lastPrice">0.5850</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">RIFUSDT</div>
                    <div class="change buy">+45.56%</div>
                    <div class="lastPrice">0.2262</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">USTCUSDT</div>
                    <div class="change buy">+10.68%</div>
                    <div class="lastPrice">0.02976035</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">LINKUSDT</div>
                    <div class="change sell">-1.18%</div>
                    <div class="lastPrice">20.166</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DOGEUSDT</div>
                    <div class="change buy">+3.20%</div>
                    <div class="lastPrice">0.08583</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">OMUSDT</div>
                    <div class="change sell">-0.18%</div>
                    <div class="lastPrice">0.27733</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">VTHOUSDT</div>
                    <div class="change buy">+49.27%</div>
                    <div class="lastPrice">0.004572</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">MATICUSDT</div>
                    <div class="change buy">+0.50%</div>
                    <div class="lastPrice">0.8920</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">TRXUSDT</div>
                    <div class="change buy">+1.90%</div>
                    <div class="lastPrice">0.13136</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">PEPEUSDT</div>
                    <div class="change buy">+13.51%</div>
                    <div class="lastPrice">0.00000126</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">PROMUSDT</div>
                    <div class="change sell">-10.56%</div>
                    <div class="lastPrice">11.558</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DYMUSDT</div>
                    <div class="change buy">+14.08%</div>
                    <div class="lastPrice">8.234</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">API3USDT</div>
                    <div class="change buy">+32.45%</div>
                    <div class="lastPrice">4.727</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">DOTTRY</div>
                    <div class="change buy">+1.44%</div>
                    <div class="lastPrice">240.0</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">AGLDUSDT</div>
                    <div class="change buy">+3.90%</div>
                    <div class="lastPrice">1.359</div>
                    <div class="foot-line"></div>
                  </div>
                  <div class="previewItemWrap">
                    <div class="symbol">STPTUSDT</div>
                    <div class="change buy">+0.79%</div>
                    <div class="lastPrice">0.05586</div>
                    <div class="foot-line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriceFooter;
